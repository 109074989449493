<template>
  <v-app>
    <v-app-bar app flat color="grey lighten-4">
      <v-app-bar-nav class="ml-1">
        <img
          src="https://storage.googleapis.com/visual-identity/logo/2020-slim.svg"
          alt="Logo HEIG-VD"
          height="40"
      /></v-app-bar-nav>
      <div class="ml-3">Mes annonces d'absence</div>
      <v-spacer></v-spacer>
      <user-profile />
    </v-app-bar>
    <v-main>
      <v-container>
        <v-row>
          <v-col>
            <v-timeline dense v-if="absences && absences.length > 0">
              <v-timeline-item
                v-for="(absence, i) in absences"
                :key="i"
                :color="
                  ['pending', 'cancelled'].indexOf(absence.status) > -1
                    ? 'grey'
                    : absence.status === 'accepted'
                    ? 'green'
                    : 'red'
                "
                small
                class="my-4"
              >
                <v-row class="pt-1">
                  <v-col md="4" cols="12" class="pa-1 pa-md-5">
                    <div>
                      <div :class="`headline font-weight-light`">
                        {{ absence.type }}
                        <div class="body-2">
                          {{
                            format(
                              parseISO(absence.original.start),
                              "dd.MM.yyyy HH:mm"
                            )
                          }}
                          -
                          {{
                            format(
                              parseISO(absence.original.end),
                              "dd.MM.yyyy HH:mm"
                            )
                          }}
                        </div>
                        <div class="caption mt-1">
                          Annonce faite le
                          {{
                            format(
                              parseISO(absence.createdAt),
                              "dd.MM.yyyy à HH:mm"
                            )
                          }}
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col
                    class="align-center pa-1 pa-md-5"
                    align-self="center"
                    md="4"
                    cols="12"
                  >
                    <v-chip
                      small
                      v-if="
                        absence.status === 'pending' ||
                        absence.status === 'processing'
                      "
                      >En cours de traitement</v-chip
                    >
                    <v-chip
                      small
                      v-if="absence.status === 'accepted'"
                      color="green white--text"
                      >Justification acceptée</v-chip
                    >
                    <v-chip
                      small
                      v-if="absence.status === 'rejected'"
                      color="red white--text"
                      >Justification refusée</v-chip
                    >
                    <v-chip small v-if="absence.status === 'cancelled'"
                      >Annulée</v-chip
                    >
                  </v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>
            <div v-else>Pas d'annonces en cours ou passées</div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";
import { parseISO, format } from "date-fns";
import UserProfile from "@/components/UserProfile";

export default {
  name: "App",
  components: { UserProfile },
  async mounted() {
    const { data: absences } = await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URI}/user-context`,
    });
    this.absences = absences;
  },
  data: () => ({
    absences: [],
    format,
    parseISO,
  }),
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{attrs:{"app":"","flat":"","color":"grey lighten-4"}},[_c('v-app-bar-nav',{staticClass:"ml-1"},[_c('img',{attrs:{"src":"https://storage.googleapis.com/visual-identity/logo/2020-slim.svg","alt":"Logo HEIG-VD","height":"40"}})]),_c('div',{staticClass:"ml-3"},[_vm._v("Mes annonces d'absence")]),_c('v-spacer'),_c('user-profile')],1),_c('v-main',[_c('v-container',[_c('v-row',[_c('v-col',[(_vm.absences && _vm.absences.length > 0)?_c('v-timeline',{attrs:{"dense":""}},_vm._l((_vm.absences),function(absence,i){return _c('v-timeline-item',{key:i,staticClass:"my-4",attrs:{"color":['pending', 'cancelled'].indexOf(absence.status) > -1
                  ? 'grey'
                  : absence.status === 'accepted'
                  ? 'green'
                  : 'red',"small":""}},[_c('v-row',{staticClass:"pt-1"},[_c('v-col',{staticClass:"pa-1 pa-md-5",attrs:{"md":"4","cols":"12"}},[_c('div',[_c('div',{class:"headline font-weight-light"},[_vm._v(" "+_vm._s(absence.type)+" "),_c('div',{staticClass:"body-2"},[_vm._v(" "+_vm._s(_vm.format( _vm.parseISO(absence.original.start), "dd.MM.yyyy HH:mm" ))+" - "+_vm._s(_vm.format( _vm.parseISO(absence.original.end), "dd.MM.yyyy HH:mm" ))+" ")]),_c('div',{staticClass:"caption mt-1"},[_vm._v(" Annonce faite le "+_vm._s(_vm.format( _vm.parseISO(absence.createdAt), "dd.MM.yyyy à HH:mm" ))+" ")])])])]),_c('v-col',{staticClass:"align-center pa-1 pa-md-5",attrs:{"align-self":"center","md":"4","cols":"12"}},[(
                      absence.status === 'pending' ||
                      absence.status === 'processing'
                    )?_c('v-chip',{attrs:{"small":""}},[_vm._v("En cours de traitement")]):_vm._e(),(absence.status === 'accepted')?_c('v-chip',{attrs:{"small":"","color":"green white--text"}},[_vm._v("Justification acceptée")]):_vm._e(),(absence.status === 'rejected')?_c('v-chip',{attrs:{"small":"","color":"red white--text"}},[_vm._v("Justification refusée")]):_vm._e(),(absence.status === 'cancelled')?_c('v-chip',{attrs:{"small":""}},[_vm._v("Annulée")]):_vm._e()],1)],1)],1)}),1):_c('div',[_vm._v("Pas d'annonces en cours ou passées")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }